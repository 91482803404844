import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "./apolloClient";

import { ThemeProvider } from "styled-components";
import { makeAppRoute } from "./Router";
import { GlobalProvider } from "./Context/GlobalProvider";
import theme from "./Consts/theme";
import MasterLayout from "./Pages/MasterLayout";
import Categories from "./Pages/Categories";
import GlobalStyle from "./Consts/GlobalStyle";
import Profile from "./Pages/Profile";
import Points from "./Pages/Points";
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import Survey from "./Pages/Survey";
import UserAuth from "./Pages/UserAuth";
import TalentMarket from "./Pages/TalentMarket";
import PopUpMessage from "./Components/Notification/PopUpMessage";
import NotFound from "./Components/NotFound/NotFound";
import Welcome from "./Pages/Welcome";
import Onboarding from "./Pages/Onboarding";
import ProfileSection from "./Pages/ProfileSection";
import BrainsterChat from "./Pages/BrainsterChat/BrainsterChat";
import OrderDetails from "./Pages/OrderDetails";

const loader = document.querySelector(".preloader");
const showLoader = () => loader?.classList.remove("preloader");
const addClass = () => loader?.classList.add("loader-hide");

function App() {
  useEffect(() => {
    showLoader();
    addClass();
  }, []);
  return (
    <ApolloProvider client={client}>
      <GlobalProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <BrowserRouter>
            <PopUpMessage></PopUpMessage>
            <Routes>
              {/* ------------------------------- PUBLIC ROUTES ----------------------------------- */}

              <Route
                path="/login"
                element={
                  <UserAuth
                    title="Login"
                    subtitle="This is a login form only for accepted members of the Academies"
                  />
                }
              />

              <Route
                path="/request-password-reset"
                element={
                  <UserAuth
                    title="Request Password Reset"
                    subtitle="Please enter your email below."
                  />
                }
              />
              <Route
                path="/password-reset/:token"
                element={
                  <UserAuth
                    title="Choose a password"
                    subtitle="Create or reset your password to access your account."
                  />
                }
              />

              {/* ------------------------------- PROTECTED ROUTES ----------------------------------- */}
              <Route path="/" element={<ProtectedRoutes />}>
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/onboarding/:step" element={<Onboarding />} />
                <Route path="/" element={<MasterLayout />} />
                <Route path="category/:category" element={<Categories />} />
                <Route path="/my-profile" element={<Profile />} />
                <Route
                  path="/my-profile/:section"
                  element={<ProfileSection />}
                />
                <Route
                  path="/my-profile/my-payments/:orderID"
                  element={<OrderDetails />}
                />
                <Route path="/points/:user_id" element={<Points />} />
                <Route path="/survey/:survey_id" element={<Survey />} />
                <Route path="/offer" element={<TalentMarket />} />
                <Route path="/aida" element={<BrainsterChat />} />

                <Route
                  path="*"
                  element={
                    <NotFound
                      textBtn="Back to Home"
                      to={makeAppRoute("home")}
                    />
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </GlobalProvider>
    </ApolloProvider>
  );
}

export default App;
